import { graphql, navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import { useMemo, useState } from "react";
import Tilt from "react-tilt";
import Button, { LinkButton, ShowMoreButton } from "../components/Button";
import {
  MainContainer,
  PageContainer,
  SkewMainContainer,
} from "../components/Container";
import Footer from "../components/Footer";
import Header, { ThemeEnum } from "../components/Header";
import Metadata from "../components/Metadata";
import SearchInput from "../components/SearchInput";
import SkewGradientBg from "../components/SkewGradientBg";
import { PageDescribe, PageTitle } from "../components/Title";
import {
  document_Stardust_AI_Product_Manual,
  DownloadButtonIcon,
  downloadCover,
  DownloadIcon,
} from "../images/download";
import { usePageMetadata } from "../metadata/page";
import LearnMoreView from "../Views/LearnMoreView";
import { PageTitleWithIcon } from "./price";

type DocItem = {
  title: string;
  desc: string;
  url: string;
};

const DocListView = (props: { lists: DocItem[] }) => {
  const { t } = useTranslation();
  return (
    <>
      {props.lists.map((list, index) => {
        return (
          <div
            key={index}
            className="grid grid-col-1 sm:grid-cols-2 gap-8 border-b pb-12 mb-14 last-child-border-none last-child-padding-none last-child-margin-none"
          >
            <div>
              <h4 className="text-2xl mb-6">{list.title}</h4>
              <p className="text-gray-500 text-sm mb-12">{list.desc}</p>
              <LinkButton
                type="primary"
                linkUrl={list.url}
                icon={<DownloadButtonIcon className="ml-4" />}
              >
                {t("资料下载")}
              </LinkButton>
            </div>
            <div className="h-54 rounded-lg shadow-offset-30">
              <img
                className="max-w-none w-full"
                src={document_Stardust_AI_Product_Manual}
                alt=""
              />
            </div>
          </div>
        );
      })}
    </>
  );
};

const DownLoadPage = () => {
  const { t } = useTranslation();
  const [showMore, setShowMore] = useState(false);
  const docList: DocItem[] = useMemo(
    () => [
      {
        title: t("星尘数据产品手册"),
        desc: t("文档类型: PDF, 文件大小: 46MB"),
        url: "/contact",
      },
    ],
    []
  );
  const { download } = usePageMetadata();
  return (
    <PageContainer>
      <Header defaultTheme={ThemeEnum.dark} />
      <Metadata {...download} />
      <SkewGradientBg id="gradient-download" />
      <MainContainer className="mb-20">
        <PageTitleWithIcon title={t("文档下载")} icon={<DownloadIcon />} />
        <div className="flex flex-wrap relative">
          <div className="w-full sm:w-[460px]">
            <PageTitle>{t("产品相关文档")}</PageTitle>
            <PageDescribe className="mt-6">
              {t("如您未发现需要下载的内容，请尝试联系销售获取。")}
            </PageDescribe>
          </div>
          <div className="relative sm:ml-8 mt-8 sm:mt-0">
            <div className="sm:absolute z-30 sm:-mt-20 rounded-md">
              <Tilt
                className="Tilt max-w-none w-full sm:w-[480px]"
                options={{
                  max: 15,
                  reverse: true,
                  speed: 1500,
                  perspective: 2000,
                }}
              >
                <img
                  className="max-w-none w-full  sm:w-[480px]"
                  src={downloadCover}
                  alt=""
                />
              </Tilt>
            </div>
          </div>
        </div>
        <div className="w-full sm:w-[378px] mt-12">
          <SearchInput />
          <div className="flex justify-between mt-7">
            <Button onClick={() => navigate("/contact")}>
              {t("联系销售")}
            </Button>
          </div>
        </div>
      </MainContainer>
      <SkewMainContainer fillClassName="bg-white" className="pt-32">
        <DocListView lists={showMore ? docList : docList.slice(0, 3)} />
        {!showMore && docList.length > 3 && (
          <ShowMoreButton className="border" onClick={() => setShowMore(true)}>
            {t("显示更多")}
          </ShowMoreButton>
        )}
      </SkewMainContainer>
      <LearnMoreView
        leftUrl={"/contact"}
        leftText={t("填写需求表格")}
        rightText={t("了解价格")}
        rightUrl={"/price"}
      />
      <Footer />
    </PageContainer>
  );
};
export default DownLoadPage;
export const query = graphql`
  query DownLoadPage($language: String!) {
    ...AllLangauages
  }
`;
